import { getToken } from '@/utils/auth';
import { MessageBox } from 'element-ui'
import { Message } from 'element-ui';
import Quill from "quill";
/*富文本编辑图片上传配置*/
const uploadConfig = {
    action:  process.env.VUE_APP_BASE_API+'/api/upload_img/',  // 必填参数 图片上传地址
    methods: 'POST',  // 必填参数 图片上传方式
    token: `${getToken()}`,  // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
    name: 'img_file',  // 必填参数 文件的参数名
    size: 500,  // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
    imgAccept: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon' ,// 可选 可上传的图片格式
    vedioAccept: '.mp4, .avi, .flv, .3gp'  // 可选 可上传的视频格式
};

// toolbar工具栏的工具选项（默认展示全部）
const toolOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{'header': 1}, {'header': 2}],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],
    [{'indent': '-1'}, {'indent': '+1'}],
    [{'direction': 'rtl'}],
    [{'size': ['small', false, 'large', 'huge']}],
    [{'header': [1, 2, 3, 4, 5, 6, false]}],
    [{'color': []}, {'background': []}],
    [{'font': []}],
    [{'align': []}],
    ['clean'],
    ['link', 'image', 'video']
];
let nowFile = '';
// 重写图片插入和视频插入方法
const BlockEmbed = Quill.import('blots/block/embed')
class VideoBlot extends BlockEmbed {
  static create(value) {
    let node = super.create()
    node.setAttribute('src', value.url)
    node.setAttribute('controls', value.controls)
    node.setAttribute('width', value.width)
    node.setAttribute('height', value.height)
    node.setAttribute('webkit-playsinline', true)
    node.setAttribute('playsinline', true)
    node.setAttribute('x5-playsinline', true)
    return node;
  }
  static value(node) {
    return {
      url: node.getAttribute('src'),
      controls: node.getAttribute('controls'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height')
    };
  }
}
VideoBlot.blotName = 'simple_video'
VideoBlot.tagName = 'video'
Quill.register(VideoBlot)

class ImgBlot extends BlockEmbed {
    static create(value) {
      let node = super.create()
      node.setAttribute('src', value.url)
      node.setAttribute('controls', value.controls)
      node.setAttribute('width', value.width)
      return node;
    }
    static value(node) {
      return {
        url: node.getAttribute('src'),
        controls: node.getAttribute('controls'),
        width: node.getAttribute('width'),
      };
    }
  }
  ImgBlot.blotName = 'simple_image'
  ImgBlot.tagName = 'img'
  Quill.register(ImgBlot)
// 文件上传方法
const uploadFile = function(fileType){
    var self = this;
    var fileInput = this.container.querySelector('input.ql-image[type=file]');

    if (nowFile !== fileType) {

        fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');
        // 设置图片参数名
        if (uploadConfig.name) {
            fileInput.setAttribute('name', uploadConfig.name);
        }
        fileInput.classList.add('ql-image');
        // 监听选择文件
        fileInput.addEventListener('change', function () {
            // 创建formData
            var formData = new FormData();
            formData.append(uploadConfig.name, fileInput.files[0]);
            formData.append('object','product');
            // 文件上传
            var xhr = new XMLHttpRequest();
            xhr.open(uploadConfig.methods, uploadConfig.action, true);
            // 设置请求头
            xhr.setRequestHeader("Authorization", uploadConfig.token);
            // 上传数据成功，会触发
            xhr.onload = function (e) {
                console.log(xhr)
                if (xhr.status === 200) {
                    var res = JSON.parse(xhr.responseText);
                    let length = self.quill.getSelection(true).index;
                    //这里很重要，你图片上传成功后，img的src需要在这里添加，res.data.file_url就是你服务器返回的图片链接。
                    // 使用方式
                    fileType==="image"?self.quill.insertEmbed(length, 'simple_image', {
                        url:res.data.file_url,
                        controls: 'controls',
                        width: '80%',
                    }):
                    self.quill.insertEmbed(length, 'simple_video', {
                        url:res.data.file_url,
                        width: '100%',
                        height: '100%'
                    })

                }
                fileInput.value = ''
            };
            if(fileType==="video"){
                // 开始上传数据
                xhr.upload.onloadstart = function (e) {
                    fileInput.value = ''
                    MessageBox({
                        dangerouslyUseHTMLString: true,
                        title: "视频上传中请等待",
                        message: '<div style="width: 300px; height: 20px; border: 1px solid #000; border-radius: 15px;"><div id="uploadShow" style="height: 20px;width: 10px; background-color: #007bff; border-radius: 15px; "></div></div>',
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        showClose: false,
                    });
                };
            // 上传数据回显,视频上传才有
                xhr.upload.onprogress  = function (evt) {
                    let uploadShow = document.getElementById('uploadShow');
                    if (evt.lengthComputable) {
                        uploadShow.style.width = Math.round(evt.loaded / evt.total * 100) + "%"
                        console.log('当前上传进度'+Math.round(evt.loaded / evt.total * 100) + "%")

                    }
                };
            };
            // 当发生网络异常的时候会触发，如果上传数据的过程还未结束
            xhr.upload.onerror = function (e) {
            };
            // 上传数据完成（成功或者失败）时会触发
            xhr.upload.onloadend = function (e) {
                Message.success("上传结束")
                MessageBox.close();
            };
            xhr.send(formData)
        });
        this.container.appendChild(fileInput);
    }
    // 根据上传方式判断文件选择类型
    fileInput.setAttribute('accept',fileType==="image"?uploadConfig.imgAccept:uploadConfig.vedioAccept);
    // 记录当前上传input
    nowFile = fileType
    fileInput.click();
};

// 修改原方法
const handlers = {
    image: function image() {
        // 图片上传
         uploadFile.call(this,"image");
    },
    video: function video(){
        // 上传视频的两种方式
        MessageBox( {
            title: "请选择上传方式",
            distinguishCancelAndClose: true,
            confirmButtonText: '文件上传',
            cancelButtonText: '链接上传',
            showCancelButton: true,
            }).then(() => {
                // 文件上传接口
                uploadFile.call(this,"video");
            })
            .catch(() => {
                // 链接上传显示
                MessageBox.prompt('请输入视频链接', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /^https?.+\.(swf|avi|flv|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4)$/i,
                    inputErrorMessage: '视频链接不正确'
                  }).then(({ value }) => {
                    this.quill.insertEmbed(length, 'simple_video', {
                        url: value,
                        width: '100%',
                        height: '100%'
                    })
                  }).catch(value=>{
                    console.log(value)})   
            });   
    }
};

export default {
    placeholder: '',
    theme: 'snow',  // 主题
    modules: {
        toolbar: {
            container: toolOptions,  // 工具栏选项
            handlers: handlers  // 事件重写
        }
    }
};