<template>
  <div class="industryDynamicsDetail">
    <div class="title">动态详情</div>
    <div class="body">
      <div class="inputBody">
        <div>标题名:</div>
        <div class="inputTitle"><el-input v-model="news_title" placeholder="请输入标题"></el-input></div>
      </div>
      <div class="inputBody">
        <div>优先级:</div>
        <div><el-input v-model="priority" type="number" placeholder="请输入优先级"></el-input></div>
      </div>
      <div class="inputBody">
        <div>点击量:</div>
        <div><el-input v-model="news_count" type="number" placeholder="请输入点击量级"></el-input></div>
      </div>
      <div class="inputBody marginBody">
        <div >阅读权限:</div>
        <div>
          <el-radio-group v-model="permission">
            <el-radio label="1">所有人可见</el-radio>
            <el-radio label="2">奥普工程师可见</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="inputBody marginBody" >
        <div>封面图片:</div>
        <div>
          <el-upload
            class="avatar-uploader"
            :action="actionUrl"
            :show-file-list="false"
            :headers="headersText"
            name="img_file"
            :on-success="handleAvatarSuccess">
            <img v-if="news_image" :src="news_image" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>

        </div>
      </div>
      <div class="inputBody marginBody">
        <div>内容:</div>
        <div>
          <quill-editor
            v-model="content"
            ref="myQuillEditor"
            :options="editorOption"
            @focus="onEditorFocus($event)"
            @blur="onEditorBlur($event)"
            @change="onEditorChange($event)"
            class="editor"
          ></quill-editor>
        </div>
      </div>
    </div>
    <div class="bottomBtn">
      <el-button type="primary" @click="industry_dynamics_setInfoFun()">保存</el-button>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth';
import { quillEditor } from 'vue-quill-editor'
import apiDetail from '@/api/other.js'
import quillConfig from '@/assets/js/quill-config.js'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { Message } from 'element-ui';
export default {
  data() {
    return {
      news_id: "",
      news_title: "",
      release_time_stamp: "",
      priority: 1,
      news_count: 1,
      permission: "1",
      news_image: "",
      is_release: "False",
      // 富文本编辑器默认内容
      content: `请填写内容...`,
      //富文本编辑器配置
      editorOption: quillConfig,
    }
  },
  components: {
    quillEditor
  },
  created() {
    let query = this.$route.query
    if(query.news_id !== undefined) {
      this.news_id = query.news_id;
      this.news_title = query.news_title;
      this.release_time_stamp = query.release_time_stamp;
      this.news_image = query.news_image;
      this.content = query.content;
      this.news_count = query.news_count;
      this.priority = query.priority;
      this.permission = query.permission;
      this.is_release = query.is_release === 'true'?"True":"False";
    }
  },
  computed: {
    actionUrl() {
      return process.env.VUE_APP_BASE_API+'/api/upload_img/'
    },
    headersText() {
      return {
        'Authorization': `${getToken()}`
      }
    },
    //当前富文本实例
    editor() {
      return this.$refs.myQuillEditor.quill;
    }
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.news_image = res.data.file_url;
      console.log(this.news_image)
    },
    //失去焦点事件
    onEditorBlur(quill) {
      console.log('editor blur!', quill)
    },
    //获得焦点事件
    onEditorFocus(quill) {
      console.log('editor focus!', quill)
    },
    // 准备富文本编辑器
    onEditorReady(quill) {
      console.log('editor ready!', quill)
    },
    //内容改变事件
    onEditorChange({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.content = html
    },
    // 新增/修改行业动态
    industry_dynamics_setInfoFun() {
      let nowDate = new Date();
      this.release_time_stamp = new Date().Format("yyyy-MM-dd hh:mm:ss");
      let param = {
      news_title: this.news_title,
      release_time_stamp: this.release_time_stamp,
      news_image: this.news_image,
      content: this.content,
      news_count: this.news_count+"",
      priority: this.priority+"",
      permission: this.permission,
      is_release: this.is_release,
      };
      this.news_id === ""?"":param.news_id=this.news_id;
      apiDetail.industry_dynamics_setInfo(param).then(res=>{
        Message.success("保存成功")
      })
    },
  },

}
</script>
<style lang="scss" >
.industryDynamicsDetail {
  margin: px(20);
  .title {
    font-size: px(20);
    font-weight: bold;
    color: #111111;
    margin-bottom: px(20);
  }
  .body{
    // display: flex;
    &>div{
      margin-top: 10px;
      font-size: 18px;
      font-weight: 600;
    }
    .marginBody{
      margin-top: 20px;
    }
    .inputBody{
      display: flex;
      &>div{
        margin-left: 20px;
      } 
      .inputTitle {
        .el-input,
        .el-input__inner{
          width: 300px;
        }
      }   
      
    }
  }
  .bottomBtn{
    text-align: center;
  }
}
// 图片上传
  .avatar-uploader .el-upload {
    border: 1px dashed #CCC;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  // 图片上传结束
  // 富文本
  .editor {
  line-height: normal !important;
  height: 500px;
  margin-bottom: 100px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
  // 富文本结束
</style>